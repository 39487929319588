import React, { Component } from "react";
import { graphql } from "gatsby";
import styled from 'styled-components';

import Layout from '../layouts/Layout/Layout';
import PostPreview from '../components/PostPreview/PostPreview';
import TextBlock from '../components/TextBlock/TextBlock';
import Divider from '../components/UI/Divider/Divider';
import MinorHeading from '../components/UI/MinorHeading/MinorHeading';
import SocialsImage from '../images/socials-image.jpg'

const PostWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    @media ${props => props.theme.media.tabletL} {
      width: 100%;
      justify-content: center;
  }
`;

const Paragraph = styled.p`
    padding: 5px 2px;
    font-size: 1.1em;
    @media ${props => props.theme.media.mobileL} {
      font-size: 1em;
  }
`;


class IndexPage extends Component {
  render() {
    const postArray = this.props.data.allMarkdownRemark.edges;
    const posts = postArray.map((post, index) => {
      const modifiedExcerpt = post.node.excerpt.replace(/\.([^\s\d])/g, '. $1')
      return (
        <PostPreview
          key={index}
          heading={post.node.frontmatter.title}
          date={post.node.frontmatter.date}
          description={modifiedExcerpt}
          path={post.node.fields.slug}
          image={post.node.frontmatter.image.childImageSharp.fluid}
        />
      )
    })
    return (
      <Layout
        title='Front-End Stumbles - a blog dedicated to all things front-end dev.'
        description='Front-End Stumbles is a blog written by a newbie to the world of coding to keep track of things learned and stumbled over and to avoid making the same mistakes a hundred times.'
        image={SocialsImage}
        url='https://www.frontendstumbles.com'
        type='website'
      >
        <TextBlock heading='Adventures in front-end code'>
          <Paragraph>
            Front-End Stumbles is a blog written by a self-taught front end dev to keep track of things learned and stumbled over and to avoid making the same mistakes a hundred times (looking at you, graphQL).
                        </Paragraph>

          <Paragraph>
            I really enjoy working with JAMstack tech and post a lot about it, especially GatsbyJS.<span role="img" aria-label="thumbs up"> 👍</span>
                        </Paragraph>
        </TextBlock>
        <Divider />
        <MinorHeading>Latest posts</MinorHeading>
        <PostWrapper>
          {posts}
        </PostWrapper>
      </Layout>

    )
  }
}

export default IndexPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
          node {
            fields {
              slug
            }
            excerpt    
            frontmatter {
                  date(formatString: "DD, MMMM, YYYY")
                  title
                  description
                  image {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
          }
        }
      }
  }
`
