import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import MinorHeading from '../UI/MinorHeading/MinorHeading';

const PostPreviewStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 250px;
    margin: 20px;
    
    @media ${props => props.theme.media.mobileL} {
        max-width: none;
        width: 100%;
        align-items: center;
    }
`;

const ImageStyled = styled(Img)`
    height: 250px;
    width: 250px;
    object-fit: cover;

    @media ${props => props.theme.media.mobileL} {
        width: 100%;
        padding-bottom: 100%;
    }
`;

const Date = styled.small`
    font-size: 0.8em;
`;

const Description = styled.p`
    padding: 5px 0;
`;

const ReadMore = styled(Link)`
    padding: 5px 0;
    font-weight: 700;
`;

const StyledLink = styled(Link)`
    width: 100%;
`;



const postPreview = (props) => {
    return (
        <PostPreviewStyled>
            <StyledLink to={props.path}>
                <ImageStyled fluid={props.image} />
                <MinorHeading textColor={props => props.theme.colors.secondaryAccent}>{props.heading}</MinorHeading>
            </StyledLink>
            <Date>{props.date}</Date>
            <Description>{props.description}...</Description>
            <ReadMore to={props.path}>Read more →</ReadMore>

        </PostPreviewStyled>
    )
}

postPreview.propTypes = {
    heading: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
}


export default postPreview;