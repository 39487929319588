import React from 'react';
import styled from 'styled-components';

const DividerStyled = styled.div`
    height: 2px;
    width: 100%;
    margin: 20px 0;
    background: ${props => props.theme.colors.secondaryAccent};
`;


const divider = (props) => {
    return (
        <DividerStyled />
    )
}


export default divider;